<template>
    <div class="cost">
        <table class="table">
            <tr>
                <th style="width:80px;">序号</th>
                <th>商务类型</th>
                <th>工作内容</th>
                <th>商务占比规则</th>
                <th>商务占比</th>
                <th>参与分配方规则</th>
                <th>参与分配方</th>
            </tr>


            <template v-if="projectInformation.businessType =='WSQGTJBLXM'">
                <tr>
                    <td>无事前沟通竞标类项目</td>
                    <td>提供有效项目信息</td>
                    <td>1%</td>
                    <td></td>
                    <td>信息提供人员</td>
                    <td></td>
                </tr>
                <tr>
                    <td>无事前沟通竞标类项目</td>
                    <td>投标文件技术方案编制</td>
                    <td>0%~30%</td>
                    <td></td>
                    <td>技术方案编制部门</td>
                    <td></td>
                </tr>
                <tr>
                    <td>无事前沟通竞标类项目</td>
                    <td>关键沟通</td>
                    <td>0%~30%</td>
                    <td></td>
                    <td>项目沟通关键人员</td>
                    <td></td>
                </tr>
                <tr>
                    <td>无事前沟通竞标类项目</td>
                    <td>其他</td>
                    <td>剩余比例</td>
                    <td></td>
                    <td>公司</td>
                    <td></td>
                </tr>
            </template>


            <template v-if="projectInformation.businessType =='YSQGTXKHXM'">
                <tr>
                    <td>有事前沟通新客户项目</td>
                    <td>提供有效项目信息</td>
                    <td>1%~3%</td>
                    <td></td>
                    <td>信息提供人员</td>
                    <td></td>
                </tr>
                <tr>
                    <td>有事前沟通新客户项目</td>
                    <td>关键沟通</td>
                    <td>25%~97%（减去有效引荐费用）</td>
                    <td></td>
                    <td>项目沟通关键人员</td>
                    <td></td>
                </tr>
                <tr>
                    <td>有事前沟通新客户项目</td>
                    <td>有效引荐</td>
                    <td>0%~15%</td>
                    <td></td>
                    <td>引荐人</td>
                    <td></td>
                </tr>
                <tr>
                    <td>有事前沟通新客户项目</td>
                    <td>售前与投标技术方案及咨询</td>
                    <td>0%~30%</td>
                    <td></td>
                    <td>售前与投标技术方案及咨询部门</td>
                    <td></td>
                </tr>
                <tr>
                    <td>有事前沟通新客户项目</td>
                    <td>商务跟进</td>
                    <td>0%~10%</td>
                    <td></td>
                    <td>商务经理/项目跟进人员</td>
                    <td></td>
                </tr>
                <tr>
                    <td>有事前沟通新客户项目</td>
                    <td>其他</td>
                    <td>剩余比例</td>
                    <td></td>
                    <td>公司</td>
                    <td></td>
                </tr>
            </template>


            <template v-if="projectInformation.businessType =='YSQGTLKHXM'">
                <tr>
                    <td>有事前沟通老客户项目</td>
                    <td>提供有效项目信息</td>
                    <td>1%~3%</td>
                    <td></td>
                    <td>信息提供人员</td>
                    <td></td>
                </tr>
                <tr>
                    <td>有事前沟通老客户项目</td>
                    <td>关键沟通</td>
                    <td>25%~97%（减去历史商务关系与上一项目实施团队费用）</td>
                    <td></td>
                    <td>项目沟通关键人员</td>
                    <td></td>
                </tr>
                <tr>
                    <td>有事前沟通老客户项目</td>
                    <td>历史商务关系</td>
                    <td>关键沟通费用的0%~30%</td>
                    <td></td>
                    <td>历史关系对接人</td>
                    <td></td>
                </tr>
                <tr>
                    <td>有事前沟通老客户项目</td>
                    <td>上一项目实施团队</td>
                    <td>关键沟通费用的0%~35%</td>
                    <td></td>
                    <td>驻场团队或实施部门</td>
                    <td></td>
                </tr>
                <tr>
                    <td>有事前沟通老客户项目</td>
                    <td>售前与投资技术方案及咨询</td>
                    <td>0%~30%</td>
                    <td></td>
                    <td>售前与投资技术方案及咨询部门</td>
                    <td></td>
                </tr>
                <tr>
                    <td>有事前沟通老客户项目</td>
                    <td>商务跟进</td>
                    <td>0%~10%</td>
                    <td></td>
                    <td>项目经理/项目跟进人员</td>
                    <td></td>
                </tr>
                <tr>
                    <td>有事前沟通老客户项目</td>
                    <td>其他</td>
                    <td>剩余比例</td>
                    <td></td>
                    <td>公司</td>
                    <td></td>
                </tr>
            </template>

        </table>
    </div>
</template>
<script>


import {mapState} from "vuex";
import {getUUID} from "@/util/common";
export default {
    components: {
        Dictionary: () => import("@/components/Dictionary.vue"),
    },
    props: {
        options: {
            type: Object,
            default:function(){
                return {}
            }
        },
    },
    data() {
        return {
            disabled:true,
            dictData:{

            },
        };
    },
    inject:['refresh'],
    computed: {
        ...mapState({
            projectInformation: state => state.project.projectInformation,
        }),
    },
    watch: {

    },
    filters:{

    },
    created() {

    },
    methods: {

    },
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";

</style>
