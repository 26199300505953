var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cost"},[_c('table',{staticClass:"table"},[_vm._m(0),(_vm.projectInformation.businessType =='WSQGTJBLXM')?[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4)]:_vm._e(),(_vm.projectInformation.businessType =='YSQGTXKHXM')?[_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10)]:_vm._e(),(_vm.projectInformation.businessType =='YSQGTLKHXM')?[_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17)]:_vm._e()],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{staticStyle:{"width":"80px"}},[_vm._v("序号")]),_c('th',[_vm._v("商务类型")]),_c('th',[_vm._v("工作内容")]),_c('th',[_vm._v("商务占比规则")]),_c('th',[_vm._v("商务占比")]),_c('th',[_vm._v("参与分配方规则")]),_c('th',[_vm._v("参与分配方")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("无事前沟通竞标类项目")]),_c('td',[_vm._v("提供有效项目信息")]),_c('td',[_vm._v("1%")]),_c('td'),_c('td',[_vm._v("信息提供人员")]),_c('td')])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("无事前沟通竞标类项目")]),_c('td',[_vm._v("投标文件技术方案编制")]),_c('td',[_vm._v("0%~30%")]),_c('td'),_c('td',[_vm._v("技术方案编制部门")]),_c('td')])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("无事前沟通竞标类项目")]),_c('td',[_vm._v("关键沟通")]),_c('td',[_vm._v("0%~30%")]),_c('td'),_c('td',[_vm._v("项目沟通关键人员")]),_c('td')])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("无事前沟通竞标类项目")]),_c('td',[_vm._v("其他")]),_c('td',[_vm._v("剩余比例")]),_c('td'),_c('td',[_vm._v("公司")]),_c('td')])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("有事前沟通新客户项目")]),_c('td',[_vm._v("提供有效项目信息")]),_c('td',[_vm._v("1%~3%")]),_c('td'),_c('td',[_vm._v("信息提供人员")]),_c('td')])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("有事前沟通新客户项目")]),_c('td',[_vm._v("关键沟通")]),_c('td',[_vm._v("25%~97%（减去有效引荐费用）")]),_c('td'),_c('td',[_vm._v("项目沟通关键人员")]),_c('td')])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("有事前沟通新客户项目")]),_c('td',[_vm._v("有效引荐")]),_c('td',[_vm._v("0%~15%")]),_c('td'),_c('td',[_vm._v("引荐人")]),_c('td')])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("有事前沟通新客户项目")]),_c('td',[_vm._v("售前与投标技术方案及咨询")]),_c('td',[_vm._v("0%~30%")]),_c('td'),_c('td',[_vm._v("售前与投标技术方案及咨询部门")]),_c('td')])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("有事前沟通新客户项目")]),_c('td',[_vm._v("商务跟进")]),_c('td',[_vm._v("0%~10%")]),_c('td'),_c('td',[_vm._v("商务经理/项目跟进人员")]),_c('td')])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("有事前沟通新客户项目")]),_c('td',[_vm._v("其他")]),_c('td',[_vm._v("剩余比例")]),_c('td'),_c('td',[_vm._v("公司")]),_c('td')])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("有事前沟通老客户项目")]),_c('td',[_vm._v("提供有效项目信息")]),_c('td',[_vm._v("1%~3%")]),_c('td'),_c('td',[_vm._v("信息提供人员")]),_c('td')])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("有事前沟通老客户项目")]),_c('td',[_vm._v("关键沟通")]),_c('td',[_vm._v("25%~97%（减去历史商务关系与上一项目实施团队费用）")]),_c('td'),_c('td',[_vm._v("项目沟通关键人员")]),_c('td')])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("有事前沟通老客户项目")]),_c('td',[_vm._v("历史商务关系")]),_c('td',[_vm._v("关键沟通费用的0%~30%")]),_c('td'),_c('td',[_vm._v("历史关系对接人")]),_c('td')])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("有事前沟通老客户项目")]),_c('td',[_vm._v("上一项目实施团队")]),_c('td',[_vm._v("关键沟通费用的0%~35%")]),_c('td'),_c('td',[_vm._v("驻场团队或实施部门")]),_c('td')])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("有事前沟通老客户项目")]),_c('td',[_vm._v("售前与投资技术方案及咨询")]),_c('td',[_vm._v("0%~30%")]),_c('td'),_c('td',[_vm._v("售前与投资技术方案及咨询部门")]),_c('td')])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("有事前沟通老客户项目")]),_c('td',[_vm._v("商务跟进")]),_c('td',[_vm._v("0%~10%")]),_c('td'),_c('td',[_vm._v("项目经理/项目跟进人员")]),_c('td')])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("有事前沟通老客户项目")]),_c('td',[_vm._v("其他")]),_c('td',[_vm._v("剩余比例")]),_c('td'),_c('td',[_vm._v("公司")]),_c('td')])
}]

export { render, staticRenderFns }